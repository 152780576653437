<template>
  <div class="h-full w-full flex flex-col justify-between absolute top-0 left-0 bottom-0 right-0">
    <div class="flex flex-col items-center justify-center gap-10 overflow-y-scroll flex-1 no-scrollbar">
      <slot />
    </div>
    <div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default { name: "Page" }
</script>
