<template>
  <FormPage :item="item">
    <Bubbles :text="props.item.text"/>
  </FormPage>
</template>

<script setup>
import Bubbles from "@/components/ui/Bubbles"
import { defineProps } from "vue"
import FormPage from "@/components/ui/form/FormPage"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})
</script>