/* eslint-disable */
import moment from "moment"

export default function useValidator(value, validators = []) {
  const VALIDATORS_MAP = {
    "string": _checkString,
    "name": _checkName,
    "email": _checkEmail,
    "licence": _checkLicence,
    "date": _checkDate
  }
  let validatorChecks = []

  if ((!validators || validators.length == 0) || (!validators.includes("required") && (!value || value.length == 0))) {
    return true
  }

  if ((!value || value.length == 0) && value !== false) {
    return false
  }

  validators.forEach(validator => {
    if (VALIDATORS_MAP[validator]) {
      validatorChecks.push(VALIDATORS_MAP[validator](value))
    }
  })

  return validatorChecks.every(x => !!x)
}

function _checkString(value) {
  return typeof value === "string"
}

function _checkName(value) {
  return value.length >= 1 && value.match(/^[-\\a-zàâæçéèêëïîôœùûüÿA-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ' ]*$/)
}

function _checkEmail(value) {
  return value.length >= 1 && value.match(/^(\w|\.|_|-)+[@](\w|_|-|\.)+[.]\w{2,3}$/)
}

function _checkLicence(value) {
  return value.length >= 11
}

function _checkDate(value) {
  let date = moment(value, "DD/MM/YYYY", true)
  return date.isValid() && date.year() > 1900 && date < moment()
}