<template>
  <div class="py-2 border-b border-gray05 flex gap-2 items-center text-sm">
    <strong class="whitespace-nowrap min-w-[140px]">
      {{ props.title }}
    </strong>
    <span class="text-gray01">
      {{ props.value }}
    </span>
  </div>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  value: {
    type: String,
    default: null
  }
})
</script>