<template>
  <FormPage
    :item="props.item"
    :current-value="picture">
    <div class="flex justify-start">
      <div
        v-if="picture"
        class="flex gap-2 items-center">
        <img
          class="object-contain w-40 max-h-40 rounded-lg"
          :src="picture.url" 
          @click="isOpen = true" />
        <span class="text-sm text-gray03">Cliquez sur la photo pour plus de détails.</span>
      </div>
      <div
        v-else
        class="p-6 rounded-lg bg-deepSeaBlue flex"
        @click="isOpen = true">
        <plus class="w-10 text-plainWhite" />
      </div>
    </div>
    <PicturePicker
      :type="type"
      :position="position"
      :has-error="isError"
      :picture="picture"
      v-if="isOpen"
      @picture="handlePicture"
      @close="isOpen = false" />
  </FormPage>
</template>

<script setup>
import { computed, defineProps, ref } from "vue"
import coreApi from "@/providers/core-api"
import FormPage from "@/components/ui/form/FormPage"
import PicturePicker from "@/components/ui/form/picture/PicturePicker"
import { useStore } from "vuex"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const store = useStore()

let picture = ref(null)
let isOpen = ref(false)
let isError = ref(false)
let type = ref(null)
let position = ref(null)

const currentCheckinId = computed(() => store.state.workspaceCheckin.checkin.id)

setValues()

function setValues() {
  picture.value = props.item.value

  type.value = "licence"
  position.value = props.item.value_label.replace("licence_", "")
}

function handlePicture(picturePayload) {
  coreApi.pictures.uploadPicture(currentCheckinId.value, picturePayload)
    .then(response => {
      picture.value = response
      isOpen.value = false
    })
    .catch(() => { 
      isError.value = true 
    })
}

</script>