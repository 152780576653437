<template>
  <FormPage
    :item="props.item"
    :current-value="licenceId">
    <Input
      size="full"
      has-small-border
      :placeholder="placeholder"
      :mask="mask"
      @maska="setUnmasked"
      @input="handleInput"
      v-model="inputValue" />
    <div class="flex flex-col gap-1 text-sm">
      <span
        class="underline font-medium cursor-pointer"
        @click="isOpen = !isOpen">
        Où trouver mon numéro de permis ?
      </span>
      <div
        class="text-gray01 flex flex-col gap-1"
        :class="[isOpen ? '' : 'invisible']">
        <span>
          Code composé exclusivement de chiffres (ancien format) ou de chiffres et de lettres (nouveau format). Inscrit sur votre permis de conduire en numéro 5 (ancien et nouveau format) à côté de la photo d’identité
        </span>
      </div>
    </div>
  </FormPage>
</template>

<script setup>
import {
  computed, defineEmits, defineProps, ref 
} from "vue"
import FormPage from "@/components/ui/form/FormPage"
import Input from "@/components/ui/Input.vue"
import useMask from "@/composables/useMask.js"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["input"])

let inputValue = ref("")
let licenceId = ref("")
let isOpen = ref(false)
const placeholder = computed(() => props.item.placeholder ? props.item.placeholder : null)
const mask = useMask("licence")

setValue()

function setValue() {
  inputValue.value = props.item.value
}

function setUnmasked(event) {
  licenceId.value = event.detail.unmasked
}

function handleInput(value) {
  emit("input", value)
}
</script>