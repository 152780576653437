<template>
  <div class="flex flex-col gap-10 items-center justify-between shrink-0">
    <div class="w-full flex items-center gap-4 p-4 border border-paperWhite rounded-lg shadow-md">
      <div class="bg-paperWhite w-12 h-12 rounded-full flex justify-center items-center">
        <account
          size="26"
          class="text-gray02"/>
      </div>
      <div class="flex flex-col gap-1 text-sm">
        <span class="text-gray01 font-medium">
          Assistant virtuel
        </span>
        <span class="text-gray03">
          de <span class="text-gray01 font-medium">{{ props.workspace.commercial_name }}</span>
        </span>
      </div>
    </div>
    <img
      class="h-14 max-w-full object-contain"
      :src="logo"
      alt="logo">
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  }
})

const logo = computed(() => {
  let workspaceLogo = props.workspace.theme.picture_url || null
  return workspaceLogo ? workspaceLogo : "https://maprochaineauto-public-s3.s3.eu-west-3.amazonaws.com/cdn/logo-simplycheck.png"
})
</script>