<template>
  <div
    class="absolute top-0 left-0 bottom-0 right-0 bg-plainWhite z-10 flex items-center wrap"
    ref="camera">
    <canvas
      ref="canvas"
      class="hidden"/>
    <video
      v-show="!pictureUrl"
      ref="webcam"
      :width="webcamWidth"
      :height="webcamHeight"
      autoplay="autoplay"
      playsinline="playsinline" />
    <div
      v-if="isWebcamLoading"
      class="absolute w-full flex justify-center">
      <loading class="animate-spin w-5"/>
    </div>
    <div
      v-else-if="isError && !isPictureTaken && !pictureUrl"
      class="w-full absolute text-center">
      <span v-if="type == 'defect'">
        Pour ajouter une photo d'un défaut en position : <strong>{{positionLabel}}</strong>, appuyez sur le bouton ci-dessous. 
      </span>
      <span v-else>
        Appuyez sur le bouton ci-dessous pour utiliser votre caméra et prenez en photo la position : <strong>{{positionLabel}}</strong>
      </span>
    </div>
    <slot v-else-if="!isPictureTaken" />
    <div class="h-full flex justify-center items-center">
      <img
        :src="pictureUrl"
        alt="pictureUrl"
        v-if="pictureUrl">
    </div>

    <div class="absolute top-5 px-2 w-full flex justify-between items-center z-100 gap-4">
      <span class="bg-plainBlack text-plainWhite flex items-center px-4 py-1">
        {{ positionLabel }}
      </span>

      <div
        class="rounded-full bg-plainBlack text-plainWhite p-2 w-10 h-10"
        @click="handleClose">
        <close class="h-5 w-5" />
      </div>
    </div>


    <div class="absolute bottom-5 px-2 w-full flex flex-col items-center gap-5 z-100">
      <div
        class="overflow-hidden bg-white px-3 py-2 rounded-lg shadow-lg animate-fade-in-down delay-500 opacity-0"
        v-if="isLoading">
        <span class="text-sm">
          Compression en cours...
        </span>
      </div>
      <div
        v-if="pictureUrl"
        class="flex justify-between items-center w-full">
        <Button
          class="only:mx-auto"
          :is-loading="isDeleting"
          :is-disabled="isLoading"
          v-if="canDelete && !isPictureTaken"
          type="dark"
          icon="delete"
          label="Supprimer"
          @clicked="handleDelete" />

        <Button
          v-else-if="!isPictureTaken"
          class="only:mx-auto"
          label="Reprendre"
          :is-disabled="isLoading"
          @clicked="handleCancel" />

        <Button
          v-if="isPictureTaken"
          :is-loading="isLoading"
          class="only:mx-auto"
          label="Chargement..."
          type="secondary" />

      </div>
      <template v-else-if="isError">
        <label for="picture-upload">
          <Button
            label="importer une photo"
            icon="camera"
            type="secondary"
            :is-loading="isWebcamLoading"
            :is-disabled="isDisabled" />
        </label>
        <input
          class="hidden"
          @change="uploadPicture"
          id="picture-upload"
          type="file"
          capture="environment"
          accept="image/*"  />
      </template>
      <Button
        v-else
        label="Prendre la photo"
        icon="camera"
        :is-loading="isWebcamLoading"
        :is-disabled="isDisabled"
        type="secondary"
        @clicked="handleSnapshot" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button"
import coreApi from "@/providers/core-api"
import WebCam from "webcam-easy"

export default {
  name: "Camera",
  components: { Button },
  props: {
    picture: {
      type: Object,
      default: null
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pictureUrl: null,
      blob: null,
      isPictureTaken: false,
      isWebcamLoading: false,
      isLoading: false,
      isDisabled: false,
      isDeleting: false,
      webcamWidth: 0,
      webcamHeight: 0,
      webcam: null,
      devices: [],
      isError: false
    }
  },
  created() {
    this.pictureUrl = this.picture ? this.picture.pictureUrl : null
  },
  computed: {
    positionLabel() {
      let value = this.$t(`position.${this.position}`)

      if (!value) {
        return ""
      }

      value = value[0].toUpperCase() + value.substring(1)
      return value
    },
    currentCheckinId() {
      return this.$store.state.workspaceCheckin.checkin.id
    }
  },
  mounted() {
    this.initWebcam()
  },
  methods: {
    initWebcam() {
      // https://bensonruan.com/how-to-access-webcam-and-take-photo-with-javascript/

      this.isWebcamLoading = true
      if(navigator.mediaDevices) {
        const webcamElement = this.$refs.webcam
        const canvasElement = this.$refs.canvas

        navigator.mediaDevices.getUserMedia({ video: true })
          .then((device) => {
            let webcamSettings = device.getVideoTracks()[0].getSettings()
            let webcamRatio = webcamSettings.width / webcamSettings.height
            this.webcam = new WebCam(webcamElement, "environment", canvasElement)

            let camera = this.$refs.camera
            this.webcamWidth = camera.clientWidth
            this.webcamHeight = this.webcamWidth/webcamRatio

            this.startWebcam()
          })
        
        return
      }

      this.isError = true
      this.isWebcamLoading = false
    },
    uploadPicture(picture) {
      let file = picture.target.files[0]
      let url = (window.URL || window.webkitURL).createObjectURL(file)

      const camera = this.$refs.camera
      let canvas = this.$refs.canvas
      let context = canvas.getContext("2d")

      let image = new Image()
      image.onload = function () {
        const ratio = image.height / image.width
        canvas.width = camera.clientWidth
        canvas.height = canvas.width * ratio
        context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height)

        this.blob = this.b64toBlob(canvas.toDataURL("image/png"))
        this.pictureUrl = url
        this.isPictureTaken = true
        this.handlePicture()
      }.bind(this)

      image.src = url
    },
    handleClose() {
      if (this.webcam) {
        this.webcam.stop()
      }
      this.$emit("close")
    },
    startWebcam() {
      if (this.webcam) {
        this.webcam.start()
          .catch(() => {
            this.isError = true
          })
          .finally(() => {
            this.isWebcamLoading = false
          })
        return
      }

      this.isError = true
      this.isWebcamLoading = false
    },
    handleCancel() {
      this.pictureUrl = null
      this.isPictureTaken = false
      this.startWebcam()
    },
    handleDelete() {
      this.isDeleting = true

      if (!this.picture) {
        this.handleClose()
        return
      }

      coreApi.pictures.deletePicture(this.picture.id)
        .then(() => {
          this.$emit("delete", this.picture.id)
        })
        .finally(() => {
          this.isDeleting = false
          this.handleClose()
        })
    },
    handlePicture() {
      this.isLoading = true

      let payload = new FormData()
      payload.append("picture", this.blob, "picture.png")
      payload.append("position", this.position)
      payload.append("type", this.type)

      coreApi.pictures.uploadPicture(this.currentCheckinId, payload)
        .then(picture => {
          this.$emit("picture", picture)
          this.handleClose()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleCameraLoading() {
      this.isDisabled = true
    },
    handleCameraStarted() {
      this.isDisabled = false
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1])
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: "image/png" })
    },
    handleSnapshot() {
      if (this.isLoading || this.isDisabled) {
        return
      }

      let snapshot = this.webcam.snap()

      this.pictureUrl = snapshot
      this.blob = this.b64toBlob(snapshot)
      this.isPictureTaken = true
      this.handlePicture()
    }
  }
}
</script>
