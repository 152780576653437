<template>
  <div class="flex flex-col gap-4">
    <div class="leading-3 flex justify-center">
      <span
        v-if="errorMessage"
        class="text-xs text-center font-medium text-bloodRed">{{errorMessage}}</span>
    </div>
    <div class="flex items-end justify-between relative">
      <Button
        class="only:mx-auto"
        v-if="hasPrevious"
        label="Précédent"
        @clicked="$emit('previous')" />
      <Button
        class="only:mx-auto"
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        v-if="hasNext"
        label="Suivant"
        type="secondary"
        @clicked="$emit('next')" />
      <Button
        class="only:mx-auto"
        :is-loading="isLoading"
        v-if="canSave"
        label="Enregistrer"
        type="dark"
        @clicked="$emit('save')" />
      <Button
        class="only:mx-auto"
        :is-loading="isLoading"
        v-if="canClose"
        label="Fermer"
        type="dark"
        @clicked="$emit('close')" />
      <Button
        class="only:mx-auto"
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        v-if="canFinish"
        label="Valider"
        type="secondary"
        @clicked="$emit('finish')" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue"

export default {
  name: "Footer",
  components: { Button },
  props: {
    navigations: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    hasNext() {
      return this.navigations ? !!this.navigations.next : false
    },
    hasPrevious() {
      return this.navigations ? !!this.navigations.previous : false
    },
    canFinish() {
      return this.navigations ? !!this.navigations.finish : false
    },
    canClose() {
      return this.navigations ? !!this.navigations.close : false
    },
    canSave() {
      return this.navigations ? !!this.navigations.save : false
    }
  },
}
</script>
