<template>
  <div
    class="w-full h-full flex flex-col items-start justify-start gap-10"
    @click="clickrandom">
    <span class="font-bold text-xl">{{ position }}</span>
    <div class="flex flex-col gap-10 w-full">
      <div class="flex flex-col gap-2">
        <span class="font-bold">Photo :</span>
        <PicturePicker
          :picture="picture"
          :position="picture.position"
          @picture="updatePictureUrl"/>
      </div>
      <div class="flex flex-col gap-2">
        <span class="font-bold">Défauts <span
          v-if="defectCount"
          class="text-gray02">({{defectCount}})</span> :</span>
        <div class="flex gap-2 wrap">
          <PicturePicker
            is-small
            can-delete
            :position="picture.position"
            type="defect"
            @picture="addPictureDefect" />
          <PicturePicker
            is-small
            can-delete
            v-for="(defect, index) in picture.defects"
            :key="index"
            :picture="defect"
            type="defect"
            :position="picture.position"
            @delete="deletePictureDefect" />
        </div>
      </div>
      <div
        class="flex flex-col gap-2"
        v-if="activeServices.length">
        <span class="font-bold">Avez-vous pensé à tout ?</span>
        <ServiceGroup
          :services="activeServices"
          :currency-code="currencyCode"
          @open-selection="hanleOpenSelection" />
      </div>
    </div>
    <ServiceSelection
      :currency-code="currencyCode"
      :service="serviceSelected"
      ref="service-selection" />
  </div>
</template>

<script>
import PicturePicker from "@/components/workspace-checkin/pictures/PicturePicker.vue"
import ServiceGroup from "@/components/workspace-checkin/services/ServiceGroup.vue"
import ServiceSelection from "@/components/workspace-checkin/services/ServiceSelection.vue"

export default {
  name: "PicturePosition",
  components: {
    PicturePicker,
    ServiceGroup,
    ServiceSelection
  },
  props: {
    picture: {
      type: Object,
      required: true
    }
  },
  data() {
    return { serviceSelected: {} }
  },
  computed: {
    position() {
      let value = this.$t(`position.${this.picture.position}`)

      if (!value) {
        return ""
      }

      value = value[0].toUpperCase() + value.substring(1)
      return value
    },
    defectCount() {
      return this.picture.defects.length
    },
    activeServices() {
      let services = this.$store.state.workspaceCheckin.services.map(category => category.services).flat(1)

      let activeServices = []
      let servicesIds = []

      services.forEach(service => {
        if (service.positions.includes(this.picture.position) && !servicesIds.includes(service.id)) {
          activeServices.push(service)
          servicesIds.push(service.id)
        }
      })

      return activeServices
    },
    currencyCode() {
      return this.$store.state.workspaceCheckin.workspace.settings.currency
    }
  },
  methods: {
    updatePictureUrl(picture) {
      this.$store.commit("workspaceCheckin/updatePictureUrl", {
        position: this.picture.position,
        url: picture.url,
        id: picture.id
      })
      this.$emit("picture")
    },
    addPictureDefect(picture) {
      let defects = [...this.picture.defects]
      defects.push({
        pictureUrl: picture.url,
        id: picture.id
      })

      this.updatePictureDefects(defects)
    },
    deletePictureDefect(pictureId) {
      let defects = this.picture.defects.filter(defect => defect.id !== pictureId)

      this.updatePictureDefects(defects)
    },
    updatePictureDefects(defects) {
      this.$store.commit("workspaceCheckin/updatePictureDefects", {
        position: this.picture.position,
        defects
      })
      this.$emit("picture")
    },
    hanleOpenSelection(service) {
      this.serviceSelected = service

      this.$refs["service-selection"].open()
    }
  }
}
</script>
