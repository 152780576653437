import coreApi from "@/providers/core-api"

const state = {
  workspace: null,
  appointmentId: null,
  plateNumber: null,
  mileage: null,
  pictures: [],
  checkin: {},
  services: [],
  positions: [],
  isReceptionist: false
}

const getters = {}

const mutations = {
  setWorkspace(state, workspace) {
    state.workspace = workspace
  },
  setIsReceptionist(state, value) {
    state.isReceptionist = value
  },
  setAppointmentId(state, appointmentId) {
    state.appointmentId = appointmentId
  },
  setPlateNumber(state, plateNumber) {
    state.plateNumber = plateNumber
  },
  setMileage(state, mileage) {
    state.mileage = mileage
  },
  setCheckin(state, checkin) {
    state.checkin = checkin
  },
  setPictures(state, pictures) {
    state.pictures = pictures
  },
  setServices(state, services) {
    state.services = services
  },
  setPositions(state, positions) {
    state.positions = positions
  },
  setCheckinServices(state, payload) {
    state.checkin.services = payload
  },
  updatePictureUrl(state, payload) {
    const index = state.pictures.findIndex(picture => picture.position === payload.position)
    if (index !== -1) {
      state.pictures[index].pictureUrl = payload.url
      state.pictures[index].id = payload.id
    }
  },
  updatePictureDefects(state, payload) {
    const index = state.pictures.findIndex(picture => picture.position === payload.position)
    if (index !== -1) {
      state.pictures[index].defects = payload.defects
    }
  },
  updatePictureVisited(state, position) {
    const index = state.pictures.findIndex(picture => picture.position === position)
    if (index !== -1) {
      state.pictures[index].isVisited = true
    }
  },
  addDefect(state, payload) {
    const index = state.pictures.findIndex(picture => picture.position === payload.position)
    if (index !== -1) {
      state.pictures[index].defects.push(payload)
    }
  }
}

const actions = {
  loadPictures({ commit, state }) {
    return coreApi.pictures.getPictureModels()
      .then(pictureModels => {
        let pictures = state.positions.map((position) => {
          let model = pictureModels.find(model => model.position == position)
          return { 
            position: position,
            pictureUrl: null,
            defects: [],
            modelUrl: model ? model.url : null,
            isVisited: false
          }
        })

        commit("setPictures", pictures)
        return pictures
      })
  },
  loadServices({ commit }) {
    let payload = {
      active: true,
      appointment_id: state.appointmentId
    }

    return coreApi.workshops.getWorkshopServices(state.workspace.id, payload)
      .then(services => {
        commit("setServices", services)
        return services
      })
  },
  loadCheckinPositions({ commit }) {
    return coreApi.workspaces.getWorkspaceCheckinPositions(state.workspace.id)
      .then(positions => {
        commit("setPositions", positions)
        return positions
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
