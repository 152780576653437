<template>
  <List
    type="radio"
    :item="item" />
</template>

<script>
import List from "@/components/ui/form/List.vue"

export default {
  name: "RadioList",
  components: { List },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>