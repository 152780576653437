<template>
  <div
    class="w-full flex gap-3 items-center py-2 px-3 rounded-lg border transition-colors duration-100 ease-in"
    :class="checkedClass">
    <span class="flex">
      <component
        :is="icon"
        class="w-4" />
    </span>
    <span>
      {{ props.item.label }}
    </span>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  isChecked: {
    type: Boolean,
    default: false
  }
})

const ICON_MAP = {
  "false": {
    "radio": "radio-blank",
    "checkbox": "checkbox-blank-outline" 
  },
  "true": {
    "radio": "radio-marked",
    "checkbox": "checkbox-outline" 
  }
}

const icon = computed(() => ICON_MAP[props.isChecked.toString()][props.type] )
const checkedClass = computed(() => props.isChecked ? "bg-deepSeaBlue text-plainWhite border-deepSeaBlue" : "bg-paperWhite text-plainBlack border-gray04 hover:bg-gray05 cursor-pointer")
</script>