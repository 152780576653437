<template>
  <PdfPart title="Données clients">
    <PdfTable
      :rows="customerData"
      :columns-count="2"/>
  </PdfPart>
</template>

<script>
import PdfPart from "@/components/summary/ui/PdfPart"
import PdfTable from "@/components/summary/ui/PdfTable"
import StringService from "@/services/StringService.js"

export default {
  name: "CustomerInformation",
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  components: {
    PdfPart,
    PdfTable
  },
  computed: {
    customerData() {
      let data = {}

      if (this.customer.business_name) {
        data["business_name"] = this.customer.business_name
      }

      return { 
        ...data,
        "name": this.customer.lastname,
        "firstname": this.customer.firstname,
        "email": this.customer.email,
        "phone_number": StringService.formatPhoneNumber(this.customer.phone_number)
      }
    }
  },
}
</script>
