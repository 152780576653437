<template>
  <div
    class="flex"
    :class="positionClass">
    <span class="font-medium">
      {{label}}
    </span>
    <div :class="sizeClass">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: "FormItem",
  props: {
    label: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: "top",
      validator: (value) => ["left", "top"].indexOf(value) !== -1
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["medium", "full"].indexOf(value) !== -1
    }
  },
  data() {
    const POSITION_CLASS_MAP = {
      left: "flex-row items-center gap-5 justify-between",
      top: "flex-col gap-1 mb-5"
    }

    const SIZE_CLASS_MAP = {
      medium: "max-w-sm",
      full: "max-w-full"
    }

    return {
      POSITION_CLASS_MAP,
      SIZE_CLASS_MAP 
    }
  },
  computed: {
    positionClass() {
      return this.POSITION_CLASS_MAP[this.position]
    },
    sizeClass() {
      return this.SIZE_CLASS_MAP[this.size]
    }
  }
}
</script>
