<template>
  <div class="flex flex-col gap-1 w-full">
    <ServiceItem
      v-for="service in services"
      :key="service.id"
      :service="service"
      :currency-code="currencyCode"
      @open-selection="$emit('open-selection', $event)" />
  </div>
</template>

<script>
import ServiceItem from "@/components/workspace-checkin/services/ServiceItem.vue"

export default {
  name: "ServiceGroup",
  components: { ServiceItem },
  props: {
    services: {
      type: Array,
      required: true
    },
    currencyCode: {
      type: String,
      default: null
    }
  }
}
</script>
