<template>
  <FormPage
    :item="item"
    :is-valid="isValid"
    :hide-overflow="false"
    :current-value="phoneNumber">
    <MazPhoneNumberInput
      no-flags
      show-code-on-list
      :class="{'on-top': selectOnTop}"
      :preferred-countries="preferredCountries"
      :no-use-browser-locale="noUseBrowserLocale"
      :default-country-code="defaultCountryCode"
      no-search
      :translations="translations"
      v-model="phoneNumber"
      @update="handleUpdate" />
  </FormPage>
</template>

<script>
import CountryService from "@/services/CountryService"
import FormPage from "@/components/ui/form/FormPage"
import { parsePhoneNumber } from "libphonenumber-js/max"

export default {
  name: "PhoneInput",
  components: { FormPage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  created() {
    this.phoneNumber = this.item.value
  },
  data() {
    return {
      preferredCountries: Object.keys(CountryService.COUNTRIES),
      selectOnTop: true,
      isValid: false,
      phoneNumber: "",
      translations: {
        countrySelector: { placeholder: "Code pays" },
        phoneInput: { placeholder: "Numéro de téléphone" }
      }
    }
  },
  computed: {
    noUseBrowserLocale() {
      return !!this.item.value
    },
    defaultCountryCode() {
      if (!this.noUseBrowserLocale) {
        return this.item.country_code || "FR"
      }
      
      return null
    }
  },
  methods: {
    handleUpdate(event) {
      if (!event.e164) {
        this.isValid = false
        return
      }
      this.isValid = parsePhoneNumber(event.e164).isValid()
    }
  }
}
</script>

<style lang="scss">
.m-phone-number-input {
  .m-input, .m-select {
    height: 38px !important;
  }

  .m-select-list-item {
    &.--is-selected {
      background-color: #2e86de !important;
    }
  }

  .m-input-wrapper {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-width: 1px !important;
    border-color: #6b7280 !important;
  }
  .m-select {
    .m-input-wrapper {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .m-input-label {
    display: none !important;
  }
  .m-input-input {
    padding-top: 0 !important;
  }
  .m-phone-number-input__country-flag {
    bottom: 12px !important;
  }
  .m-select-list {
    display: fixed !important;
    border: 1px solid #eee;

    &.--bottom {
      top: 100%;
      bottom: auto;
      margin-top: 5px;
    }
  }

  &.on-top {
    .m-phone-number-input__select {
      .m-select-list {
        &.--bottom {
          top: auto;
          bottom: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
