<template>
  <Page>
    <div
      v-if="isLoading"
      class="flex items-center">
      <loading class="animate-spin w-5"/>
    </div>
    <PicturePosition
      @picture="isPictureOpen = false"
      v-else-if="isPictureOpen"
      :picture="currentPicture"/>
    <div
      v-else
      class="w-full h-full flex flex-col gap-5">
      <CarParts
        :valids="validCarParts"
        :defects="defectCarParts"
        :positions="positions"
        is-clickable
        class="max-h-[300px] w-full h-full"
        @picture="handlePicture"/>
      <div class="flex-1 flex gap-1 flex-col">
        <ListItem
          :label="$t('position.' + position)"
          @click="handlePicture(position)"
          v-for="position in positions"
          :key="position">
          {{ getPositionDescription(position) }}
        </ListItem>
      </div>
    </div>
    <template v-slot:footer>
      <Footer
        :is-loading="isLoading"
        @next="handleNext"
        @previous="handlePrevious"
        @save="isPictureOpen = false"
        :navigations="footerNavigations" />
    </template>
  </Page>
</template>

<script>
import CarParts from "@/components/workspace-checkin/pages/car-pictures/CarParts.vue"
import Footer from "@/components/workspace-checkin/navigation/Footer.vue"
import ListItem from "@/components/ui/ListItem.vue"
import Page from "@/components/workspace-checkin/navigation/Page.vue"
import PicturePosition from "@/components/workspace-checkin/pictures/PicturePosition.vue"

export default {
  name: "CarPictures",
  components: {
    CarParts,
    Footer,
    ListItem,
    Page,
    PicturePosition
  },
  created() {
    if (!this.$store.state.workspaceCheckin.pictures.length || !Object.keys(this.$store.state.workspaceCheckin.services).length) {
      this.handleInit()
    }
  },
  data() {
    return { 
      isLoading: false,
      currentPicture: null,
      isPictureOpen: false
    }
  },
  props: {
    navigations: {
      type: Object,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    }
  },
  computed: {
    positions() {
      return this.$store.state.workspaceCheckin.positions
    },
    footerNavigations() {
      if (this.isPictureOpen) {
        return { save: true }
      }
      return this.navigations
    },
    defectCarParts() {
      let defects = []
      this.$store.state.workspaceCheckin.pictures.forEach((picture) => {
        if (picture.defects.length) {
          defects.push(picture.position)
        }
      })
      return defects
    },
    validCarParts() {
      let pictures = []
      this.$store.state.workspaceCheckin.pictures.forEach((picture) => {
        if (picture.pictureUrl || picture.isVisited) {
          pictures.push(picture.position)
        }
      })
      return pictures
    }
  },
  methods: {
    handleInit() {
      let promises = [
        this.$store.dispatch("workspaceCheckin/loadCheckinPositions"),
        this.$store.dispatch("workspaceCheckin/loadServices")]
      this.isLoading = true

      Promise.all(promises)
        .finally(() => this.isLoading = false)
    },
    handlePicture(position) {
      this.isPictureOpen = true
      this.currentPicture = this.$store.state.workspaceCheckin.pictures.find(picture => picture.position == position)
      this.$store.commit("workspaceCheckin/updatePictureVisited", position)
    },
    getPositionDescription(position) {
      let positionInformations = this.$store.state.workspaceCheckin.pictures.find(picture => picture.position == position)
      let defectCount = positionInformations.defects.length
      let pictureCount = positionInformations.pictureUrl ? 1 : 0

      return `${pictureCount} ${ this.$tc("picture", pictureCount) } - ${defectCount} ${ this.$tc("defect", defectCount) }`
    },
    handleNext() {      
      if (this.navigations.next) {
        this.$emit("next")
      }
    },
    handlePrevious() {
      if (this.navigations.previous) {
        this.$emit("previous")
      }
    },
  }
}
</script>