<template>
  <PdfPart title="Données véhicules">
    <PdfTable
      :rows="customerData"
      :columns-count="2"/>
  </PdfPart>
</template>

<script>
import PdfPart from "@/components/summary/ui/PdfPart"
import PdfTable from "@/components/summary/ui/PdfTable"

export default {
  name: "CustomerInformation",
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  components: {
    PdfPart,
    PdfTable
  },
  computed: {
    customerData() {
      return {
        "plate_number": this.vehicle.plate_number,
        "brand": this.vehicle.brand,
        "model": this.vehicle.model,
        "color": this.vehicle.color,
        "year_released": this.$moment(this.vehicle.year_released).format("DD/MM/YYYY"),
        "mileage": this.vehicle.mileage
      }
    }
  },
}
</script>
