<template>
  <div class="h-full flex flex-shrink-0 justify-center bg-paperWhite">
    <div class="max-w-3xl flex-1 h-full">
      <div v-if="isLoading">
        <CarLoader />
      </div>
      <div v-else-if="isError">
        Error
      </div>
      <div
        class="flex flex-col h-full p-5 gap-5 bg-plainWhite shadow-lg"
        v-else>
        <Header
          :title="activeTitle"
          :navigations="activeNavigations" />
        <div class="flex flex-1 w-full items-center overflow-hidden relative">
          <transition
            :enter-from-class="`slide-${tabDirection}-enter`"
            :leave-to-class="`slide-${tabDirection}-leave`"
            name="slide">
            <component
              :is="activeComponent"
              :navigations="activeNavigations"
              :workspace="currentWorkspace"
              @next="goTo('next')"
              @previous="goTo('previous')"
              @finish="goTo('finish')" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalServices from "@/components/workspace-checkin/pages/AdditionalServices.vue"
import CarLoader from "@/components/ui/CarLoader.vue"
import CarPictures from "@/components/workspace-checkin/pages/CarPictures.vue"
import CheckinCompleted from "@/components/workspace-checkin/pages/CheckinCompleted.vue"
import coreApi from "@/providers/core-api"
import CustomerInformation from "@/components/workspace-checkin/pages/CustomerInformation.vue"
import Header from "@/components/workspace-checkin/navigation/Header.vue"
import Signature from "@/components/workspace-checkin/pages/Signature.vue"

export default {
  name: "WorkspaceCheckin",
  components: {
    CarLoader,
    Header 
  },
  data() {
    return {
      activePageName: "information",
      tabDirection: "next",
      isLoading: false,
      isError: false,
      currentWorkspace: null
    }
  },
  computed: {
    pages() {
      return {
        information: {
          component: CustomerInformation,
          title: "Nouvel état des lieux",
          navigations: {
            previous: null,
            next: "carPictures"
          }
        },
        carPictures: {
          component: CarPictures,
          title: "Photos d'inspection",
          navigations: {
            previous: "information",
            next: this.hasServicesWithoutPosition ? "additionalServices" : "signature"
          }
        },
        additionalServices: {
          component: AdditionalServices,
          title: "Avez-vous pensé à tout ?",
          navigations: {
            previous: "carPictures",
            next: "signature"
          }
        },
        signature: {
          component: Signature,
          title: "Signature",
          navigations: {
            previous: this.hasServicesWithoutPosition ? "additionalServices" : "carPictures",
            next: null,
            finish: "completed"
          },
        },
        completed: {
          component: CheckinCompleted,
          title: "État des lieux terminé",
          navigations: {
            previous: null,
            next: null,
          }
        }
      }
    },
    activePage() {
      return this.pages[this.activePageName]
    },
    activeComponent() {
      return this.activePage.component
    },
    activeTitle() {
      return this.activePage.title
    },
    activeNavigations() {
      return this.activePage.navigations
    },
    hasServicesWithoutPosition() {
      let services = this.$store.state.workspaceCheckin.services.map(category => category.services).flat(1)

      return services.some(service => !service.positions.length)
    }
  },
  created() {
    this.setData()
    if(this.$route.query.mode == "receptionist") {
      this.$store.commit("workspaceCheckin/setIsReceptionist", true)
    }
    document.title = "SimplyCheck - coupe file"
  },
  methods: {
    goTo(direction) {
      this.activePageName = this.activeNavigations[direction]
      if (direction === "finish") {
        direction = "next"
      }
      this.tabDirection = direction
    },
    setData() {
      this.isLoading = true

      let workspaceId = this.$route.params.workspaceId
      coreApi.workspaces.readWorkspaceInfo(workspaceId)
        .then(workspace => {
          this.$store.commit("workspaceCheckin/setWorkspace", workspace)
          this.currentWorkspace = workspace
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
