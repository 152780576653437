<template>
  <PdfPart title="Photographies horodatées">
    <div class="pt-4 flex flex-col gap-8">
      <CarParts
        class="max-h-[300px] w-full h-full"
        :defects="defectCarParts"
        :positions="positions"
        default-valid />
      <PicturePosition
        :position="position[0].position"
        :pictures="position"
        v-for="position in picturesPosition"
        :key="picturesPosition[position]" />
    </div>
  </PdfPart>
</template>

<script>
import CarParts from "@/components/workspace-checkin/pages/car-pictures/CarParts.vue"
import PdfPart from "@/components/summary/ui/PdfPart"
import PicturePosition from "@/components/summary/ui/PicturePosition"

export default {
  name: "PictureList",
  components: {
    CarParts,
    PdfPart,
    PicturePosition
  },
  props: {
    pictures: {
      type: Object,
      required: true
    },
    positions: {
      type: Array,
      required: true
    }
  },
  computed: {
    picturesPosition() {
      let pictures =  this.pictures.filter(picture => ["main", "defect"].includes(picture.type))

      pictures = pictures.reduce((obj, item) => {
        obj[item.position] = obj[item.position] || []
        obj[item.position].push(item)
        return obj
      }, {})

      return pictures
    },
    defectCarParts() {
      let defects = []
      this.pictures.forEach((picture) => {
        if (picture.type == "defect") {
          defects.push(picture.position)
        }
      })
      return defects
    }
  }
}
</script>
