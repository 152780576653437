<template>
  <div class="flex flex-col items-center gap-4">
    <span
      class="w-full text-center"
      v-if="label">{{ label }} :</span>
    <vue-drawing-canvas
      class="border-2 border-gray01 rounded-lg"
      ref="signature"
      line-join="round"
      :line-width="4"
      :image="signature"
      @update:image="updateImage"
      width="300"
      height="400" />
    <Button
      @click="handleReset"
      :is-disabled="isLoading"
      size="small"
      type="dark"
      label="Effacer"
      icon="delete" />
  </div>
</template>

<script>
import Button from "@/components/ui/Button"
import VueDrawingCanvas from "vue-drawing-canvas"

export default {
  name: "SignatureInput",
  components: {
    VueDrawingCanvas,
    Button,
  },
  props: {
    label: {
      type: String,
      default: null 
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { signature: null }
  },
  methods: {
    updateImage(value) {
      this.signature = value
      this.$emit("change", this.$refs.signature.isEmpty() ? null : value)
    },
    handleReset() {
      this.$refs.signature.reset()
    }
  }
}
</script>