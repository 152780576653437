<template>
  <div class="h-12 flex items-center text-xl border-b-plainBlack border-b">
    <span class="font-bold text-plainBlack">{{title}}</span>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
