<template>
  <FormItem
    class="relative"
    :size="size"
    :label="label"
    :position="position">
    <div
      class="w-full rounded-md h-10 overflow-hidden bg-plainWhite
      flex items-center relative z-20
      transition-colors duration-200 ease-out"
      :class="borderClass">
      <div
        v-if="icon"
        class="flex items-center justify-center w-16"
        :class="textClass">
        <component
          :is="icon"
          class="w-6" />
      </div>
      <input
        :value="modelValue"
        v-maska:[mask]
        @maska="getUnmasked"
        @input="handleInput"
        :disabled="isDisabled"
        :placeholder="placeholderValue"
        :type="type"
        class="w-full h-full border-none bg-transparent font-medium p-0 pr-10 placeholder:text-gray03
        focus:outline-none focus:ring-0
        selection:bg-mandarineOrange selection:text-plainWhite"
        :class="[{'cursor-not-allowed' : isDisabled}, {'pl-2' : !icon }, textClass]">
      <span
        class="absolute flex items-center text-gray03 right-2
        transition-all duration-200 ease-in-out transform"
        :class="[hasError ? 'opacity-0': 'opacity-100']">
        {{suffix}}
      </span>
      <alert-circle
        class="absolute flex items-center text-bloodRed w-6
        transition-all duration-200 ease-in-out transform"
        :class="[hasError ? 'animate-[smallRotation_1s_ease-in-out] right-2' : '-right-8']"/>
    </div>
    <div
      class="absolute z-10 right-2 text-bloodRed text-xs whitespace-nowrap
      transition-all ease-in-out duration-200 transform"
      :class="[validationMessage ? '-bottom-5' : 'bottom-0']">
      <span>
        {{ validationMessage }}
      </span>
    </div>
  </FormItem>
</template>

<script>
import FormItem from "@/components/ui/FormItem.vue"
import { toRef } from "vue"
import useWritingText from "@/composables/useWritingText"

export default {
  name: "Input",
  components: { FormItem },
  setup(props) {
    const placeholders = toRef(props, "placeholders")
    const placeholder = toRef(props, "placeholder")

    let placeholderValue = null

    if (!placeholder.value && !placeholders.value.length) {
      return { placeholderValue }
    }

    if (placeholders.value.length > 1) {
      placeholderValue = useWritingText(placeholders)
      return { placeholderValue }
    }

    placeholderValue = placeholder

    return { placeholderValue, }
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    placeholders: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    validations: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "text",
      validator: (value) => ["text", "number"].indexOf(value) !== -1
    },
    isTrim: {
      type: Boolean,
      default: false
    },
    isUppercase: {
      type: Boolean,
      default: false
    },
    hasSmallBorder: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    },
    mask: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    const BORDER_COLOR_CLASS = {
      default: "border-gray01",
      disabled: "border-gray03",
      error: "border-bloodRed"
    }
    const TEXT_COLOR_CLASS = {
      default: "text-gray01",
      disabled: "text-gray03",
      error: "text-bloodRed"
    }

    return {
      BORDER_COLOR_CLASS,
      TEXT_COLOR_CLASS
    }
  },
  computed: {
    borderClass() {
      let border = this.hasSmallBorder ? "border " : "border-2 "
      if (this.isDisabled) {
        return border + this.BORDER_COLOR_CLASS["disabled"]
      }
      if (this.hasError) {
        return border + this.BORDER_COLOR_CLASS["error"]
      }
      return border + this.BORDER_COLOR_CLASS["default"]
    },
    textClass() {
      if (this.isDisabled) {
        return this.TEXT_COLOR_CLASS["disabled"]
      }
      if (this.hasError) {
        return this.TEXT_COLOR_CLASS["error"]
      }
      return this.TEXT_COLOR_CLASS["default"]
    },
    hasError() {
      return this.isError || this.validationMessage
    },
    validationMessage() {
      return this.validations ? this.validations[0] : null
    }
  },
  methods: {
    handleInput(event) {
      let value = event.target.value
      if (this.isTrim) {
        value = value.replace(/\s/g, "")
      }
      if (this.isUppercase) {
        value = value.toUpperCase()
      }
      this.$emit("update:modelValue", value)
      this.$emit("input", value)
      event.stopPropagation()
    },
    getUnmasked(event) {
      this.$emit("maska", event)
    }
  }
}
</script>
