import { createRouter, createWebHistory } from "vue-router"
import CheckinSummary from "@/views/summary/CheckinSummary.vue"
import HomeCheckin from "@/views/home-checkin/HomeCheckin.vue"
import Layout from "@/views/Layout.vue"
import WorkspaceCheckin from "@/views/workspace-checkin/WorkspaceCheckin.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: Layout,
  },
  {
    path: "/:workspaceId/coupe-file",
    name: "workspace-checkin",
    component: WorkspaceCheckin
  },
  {
    path: "/compte-rendu/:checkinId",
    name: "checkin-summary",
    component: CheckinSummary
  },
  {
    path: "/enregistrement-en-ligne/:appointmentId",
    name: "home-checkin",
    component: HomeCheckin
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
