<template>
  <div class="flex gap-2 text-sm flex-col items-start">
    <span class="font-bold">{{ $t(`position.${position}`)}}</span>
    <div class="grid grid-cols-2 gap-2 w-full">
      <img
        class="max-w-96 max-h-96 object-contain"
        :src="picture.url"
        crossorigin="*"
        alt="vehicle-picture"
        v-for="picture in picturePositions"
        :key="picture.id">
    </div>
  </div>
</template>

<script>
export default {
  name: "PicturePosition",
  props: {
    position: {
      type: String,
      required: true
    },
    pictures: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    picturePositions() {
      let pictureList = JSON.parse(JSON.stringify(this.pictures))

      return pictureList.sort((a, b) => {
        if (a.type == "main" && b.type == "defect") {return -1}
        return 0
      })
    }
  }
}
</script>
