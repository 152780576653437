// import coreApi from "@/providers/core-api"

const state = {}

const getters = {}

const mutations = {
  setValue(state, data) {
    state[data.label] = data.value
  } 
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}