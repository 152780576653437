import coreApiClient from "@/providers/core-api/CoreApi"

export default {
  readWorkspaceInfo(workspaceId) {
    return coreApiClient.sendRequest("get", `/v1/workspaces/${workspaceId}`, {})
  },
  getPlateAppointments(workspace_id, payload) {
    return coreApiClient.sendRequest("get", `/v1/workspaces/${workspace_id}/appointments`, payload)
  },
  getWorkspaceCheckinPositions(workspace_id) {
    return coreApiClient.sendRequest("get", `/v1/workspaces/${workspace_id}/checkin-positions`, {})
  }
}
