<template>
  <div class="h-full flex flex-col justify-between items-center gap-4">
    <div
      class="flex w-full justify-between"
      v-if="displayActions">
      <Button
        v-if="isStart"
        @clicked="handleNext"
        label="Commencer"
        type="secondary"
        :is-disabled="isActionsDisabled"
        class="only:mx-auto"/>
      <Button
        v-if="isFinish || !isStart"
        @clicked="handlePrevious"
        label="Précédent"
        class="only:mx-auto"/>
      <Button
        v-if="!isFinish && !isStart"
        @clicked="handleNext"
        label="Suivant"
        type="secondary"
        :is-disabled="isDisabled || isActionsDisabled"
        class="only:mx-auto"/>
      <Button
        v-if="isFinish"
        @clicked="handleNext"
        label="Terminer"
        type="secondary"
        :is-disabled="isDisabled || isActionsDisabled"
        :is-loading="isLoading"
        class="only:mx-auto"/>
    </div>
    <div
      class="text-gray03 flex gap-3"
      v-if="hasDescription"> 
      <component
        :is="actionIcon"
        v-if="!!actionIcon"
        class="h-5 w-5"/>
      <span
        class="text-sm"
        v-if="actionIcon">{{ actionDescription }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue"
import Button from "@/components/ui/Button.vue"

const emit = defineEmits(["next", "previous"])

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isDisabled: {
    type: Boolean,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  }
})

function handleNext() {
  emit("next")
}

function handlePrevious() {
  emit("previous")
}

const displayActions = computed(() => !props.item.actions || (props.item.actions && props.item.actions.display !== "hidden"))
const isActionsDisabled = computed(() => props.item.actions && props.item.actions.disabled)
const hasDescription = computed(() => !!props.item.actions && props.item.actions.description)
const actionDescription = computed(() =>  props.item.actions && props.item.actions.description ? props.item.actions.description : null )
const actionIcon = computed(() => props.item.actions && props.item.actions.icon ? props.item.actions.icon : null )
const isStart = computed(() => props.item.actions && props.item.actions.type == "start")
const isFinish = computed(() => props.item.actions && props.item.actions.type == "finish")
</script>
