<template>
  <PdfPart title="Objet du rendez-vous">
    <span class="inline-block pt-4">
      {{ appointment.reason }}
    </span>
  </PdfPart>
</template>

<script>
import PdfPart from "@/components/summary/ui/PdfPart"

export default {
  name: "CustomerInformation",
  components: { PdfPart, },
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>
