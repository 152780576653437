<template>
  <div class="flex flex-col gap-4">
    <div
      v-for="bubble in bubbles"
      :key="bubble"
      class="px-5 py-3 bg-paperWhite rounded-lg">
      <span class="text-sm">{{bubble}}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})

const bubbles = computed(() => props.text.trim().split("\n"))
</script>