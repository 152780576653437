<template>
  <img
    class="w-full max-w-xs max-h-16 object-contain"
    :src="workspace.theme.picture_url ? workspace.theme.picture_url : simplyCheckLogo"
    alt="logo">
</template>

<script>

export default {
  name: "WorkspaceLogo",
  data() {
    return { simplyCheckLogo: "https://maprochaineauto-public-s3.s3.eu-west-3.amazonaws.com/cdn/logo-simplycheck.png" }
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  }
}
</script>
