<template>
  <Page>
    <div v-if="isLoading">
      <loading class="animate-spin w-5"/>
    </div>
    <div v-else-if="isError">Erreur</div>
    <div
      class="w-full h-full flex flex-col justify-start items-start"
      v-else>
      <ServiceGroup
        :services="activeServices"
        :currency-code="currencyCode"
        @open-selection="hanleOpenSelection" />
      <ServiceSelection
        :currency-code="currencyCode"
        :service="serviceSelected"
        ref="service-selection" />
    </div>
    <template v-slot:footer>
      <Footer
        :is-loading="isLoading"
        @next="handleNext"
        @previous="handlePrevious"
        :navigations="navigations" />
    </template>
  </Page>
</template>

<script>
import Footer from "@/components/workspace-checkin/navigation/Footer.vue"
import Page from "@/components/workspace-checkin/navigation/Page.vue"
import ServiceGroup from "@/components/workspace-checkin/services/ServiceGroup.vue"
import ServiceSelection from "@/components/workspace-checkin/services/ServiceSelection.vue"

export default {
  name: "AdditionalServices",
  components: {
    Footer,
    Page,
    ServiceGroup,
    ServiceSelection
  },
  props: {
    navigations: {
      type: Object,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      serviceSelected: {}
    }
  },
  computed: {
    activeServices() {
      let services = this.$store.state.workspaceCheckin.services.map(category => category.services).flat(1)

      let activeServices = []
      let servicesIds = []

      services.forEach(service => {
        if (!service.positions.length && !servicesIds.includes(service.id)) {
          activeServices.push(service)
          servicesIds.push(service.id)
        }
      })

      return activeServices
    },
    currencyCode() {
      return this.$store.state.workspaceCheckin.workspace.settings.currency
    }
  },
  methods: {
    handleInit() {
      this.isLoading = true

      this.$store.dispatch("workspaceCheckin/loadServices")
        .finally(() => {
          this.isLoading = false
        })
    },
    handleNext() {
      if (this.navigations.next) {
        this.$emit("next")
      }
    },
    handlePrevious() {
      if (this.navigations.previous) {
        this.$emit("previous")
      }
    },
    hanleOpenSelection(service) {
      this.serviceSelected = service

      this.$refs["service-selection"].open()
    }
  }
}
</script>
