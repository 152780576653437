<template>
  <FormPage
    :item="props.item"
    :current-value="selectedValues">
    <div class="flex flex-col gap-2">
      <ListItem
        :type="type"
        @click="handleClick(possibility.value)"
        :item="possibility"
        v-for="possibility in possibilities"
        :key="possibility.value"
        :is-checked="checkSelected(possibility.value)"/>
    </div>
  </FormPage>
</template>

<script setup>
import { defineProps, ref } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import ListItem from "@/components/ui/form/list/ListItem.vue"

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

let selectedValues = ref([])
let possibilities = ref([])

setData()

function setData() {
  possibilities.value = JSON.parse(JSON.stringify(props.item.possibilities))

  if (props.type == "radio") { 
    selectedValues.value = props.item.value
    return
  }
  selectedValues.value = props.item.value
}

function checkSelected(value) {
  if (props.type == "radio") {
    return value === selectedValues.value
  }

  return selectedValues.value.includes(value)
}

function handleClick(item) {
  if (props.type == "radio") {
    selectedValues.value = item
    return
  }

  if (selectedValues.value.includes(item)) {
    let valueIndex = selectedValues.value.findIndex(val => val == item)
    selectedValues.value.splice(valueIndex, 1)
    return
  }

  selectedValues.value.push(item)
}
</script>