<template>
  <FormPage :item="props.item">
    <Bubbles :text="props.item.text" />
    <div class="absolute bottom-0 flex justify-center w-full items-center gap-2">
      <span class="text-xs">
        avec
      </span>
      <img
        :src="logo"
        class="h-3"
        alt="logo" />
    </div>
  </FormPage>
</template>

<script setup>
import { defineProps, ref } from "vue"
import Bubbles from "@/components/ui/Bubbles"
import FormPage from "@/components/ui/form/FormPage"
import mpaLogo from "@/assets/img/banner-maprochaineauto-crop.png"

let logo = ref(mpaLogo)

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})
</script>