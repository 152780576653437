<template>
  <FormPage
    :item="props.item"
    :current-value="item.value">
    <div class="flex flex-col gap-1">
      <Item
        title="Marque et modèle :"
        :value="vehicleLabel"/>
      <Item
        title="Immatriculation :"
        :value="plateNumber"/>
      <div
        v-if="pictureUrl"
        class="flex justify-center">
        <img
          class="max-w-[80%] max-h-[300px] rounded-lg"
          :src="pictureUrl"
          alt="car">
      </div>
    </div>
  </FormPage>
</template>

<script setup>
import { computed, defineProps } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import Item from "@/components/ui/form/courtesy/Item.vue"
import StringService from "@/services/StringService.js"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const brand = computed(() => StringService.uppercase(props.item.value.brand))
const model = computed(() => StringService.title(props.item.value.model))

const vehicleLabel = computed(() => {
  return [brand.value, model.value].join(" ")
})

// const model = computed(() => props.item.value.model || null )
const pictureUrl = computed(() => props.item.value.picture_url || null )
const plateNumber = computed(() => StringService.formatPlate(props.item.value.plate_number))

</script>