<template>
  <div class="h-full flex justify-center items-center font-bold text-5xl">
    <span class="text-mandarineOrange transition-all duration-200 ease-in-out">.</span>
    <span class="text-deepSeaBlue transition-all duration-200 ease-in-out">Checkin</span>
  </div>
</template>

<script>
export default { name: "Layout" }
</script>
