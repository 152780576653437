import { parsePhoneNumber } from "libphonenumber-js"

class StringService {
  static formatCurrency(value, currency) {
    // https://www.localeplanet.com/icu/
    const CURRENCY_MAP = {
      "CHF": "de-CH",
      "EUR": "fr-FR",
      "XPF": "fr-NC",
      "GBP": "en-GB",
      "USD": "en-US",
      "AUD": "en-AU",
      "CAD": "fr-CA",
    }

    if (!Object.keys(CURRENCY_MAP).includes(currency)) {
      currency = "EUR"
    }

    let options = {
      style: "currency",
      currency: currency
    }

    if (value % 1 == 0) {
      options.maximumFractionDigits = 0
    }

    const formatter = new Intl.NumberFormat(CURRENCY_MAP[currency], options)
    return formatter.format(value)
  }

  static title(value) {
    if (!value) {
      return ""
    }

    value = (value + "").toLowerCase()
    value = value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    return value
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  static uppercase(value) {
    if (value instanceof Array) {
      return value.map(e => e.toUpperCase())
    }
    return value ? value.toUpperCase() : ""
  }

  static ucfirst(value) {
    if (!value) {
      return ""
    }

    value = value + ""
    return value[0].toUpperCase() + value.substring(1)
  }

  static formatPlate(value, char=" ") {
    return value ? value.replace(/\s/g, "").toUpperCase().match(/\d+|\D+/g).join(char) : ""
  }

  static formatPhoneNumber(value, displayDiallingCode=true) {
    if (!value) {
      return null
    }
    
    try {
      const parsed = parsePhoneNumber(value, null)
      return displayDiallingCode ? parsed.formatInternational() : parsed.formatNational()
    } catch {
      return value
    }
  }
}

export default StringService