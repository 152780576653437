<template>
  <FormPage
    :item="props.item"
    :current-value="picture">
    <div class="flex justify-start">
      <div
        class="p-6 rounded-lg bg-deepSeaBlue flex"
        @click="isOpen = true">
        <plus class="w-10 text-plainWhite" />
      </div>
    </div>
    <PicturePicker
      v-if="isOpen"
      @close="isOpen = false"/>
  </FormPage>
</template>

<script setup>
import { defineProps, ref } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import PicturePicker from "@/components/ui/form/picture/PicturePicker"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

let picture = ref(null)
let isOpen = ref(false)

setValues()

function setValues() {
  picture.value = props.item.value
}

</script>