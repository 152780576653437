import AppointmentProvider from "./AppointmentProvider"
import CheckinProvider from "./CheckinProvider"
import PictureProvider from "./PictureProvider"
import WorkshopProvider from "./WorkshopProvider"
import WorkspaceProvider from "./WorkspaceProvider"

export default {
  appointments: AppointmentProvider,
  checkins: CheckinProvider,
  pictures: PictureProvider,
  workshops: WorkshopProvider,
  workspaces: WorkspaceProvider
}
