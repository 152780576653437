<template>
  <PdfPart title="Prestations additionnelles">
    <PdfList :rows="services" />
  </PdfPart>
</template>

<script>
import PdfList from "@/components/summary/ui/PdfList"
import PdfPart from "@/components/summary/ui/PdfPart"

export default {
  name: "ServicesAsked",
  components: {
    PdfPart,
    PdfList
  },
  props: {
    services: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>
