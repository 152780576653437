<template>
  <div class="flex flex-col gap-2 sm:gap-3 pb-8">
    <span class="uppercase font-bold text-md sm:text-lg">{{ title }}</span>
    <div class="border-t-2 border-plainBlack text-xs sm:text-sm">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PdfPart",
  props: {
    title: {
      type: String,
      required: true
    },
  }
}
</script>
