<template>
  <div class="grid sm:grid-cols-2 pt-4">
    <span
      class="p-2 flex items-center border-b border-gray05 "
      v-for="(value, key) in rows"
      :key="key">
      <strong class="w-36 inline-block">
        {{ $t(`pdf.${key}`) }}
      </strong>
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PdfTable",
  props: {
    rows: {
      type: Object,
      required: true
    }
  },
}
</script>
