<template>
  <div class="flex flex-col pt-4">
    <span
      class="py-2 flex-col sm:flex-row border-b border-gray05"
      v-for="(value, key) in rows"
      :key="key">
      <strong class="w-96 inline-block">
        {{ value.label }}
      </strong>
      {{ $t(`pdf.${value.action}`) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PdfList",
  props: {
    rows: {
      type: Object,
      required: true
    },
  },
}
</script>
