<template>
  <FormPage
    :item="props.item"
    :current-value="dateInput">
    <Input
      size="full"
      has-small-border
      placeholder="dd/mm/yyyy"
      :mask="mask"
      @input="handleInput"
      v-model="dateInput" />
  </FormPage>
</template>

<script setup>
import { checkMoment, formatMoment } from "@/composables/useMoment.js"
import { defineEmits, defineProps, ref } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import Input from "@/components/ui/Input.vue"
import useMask from "@/composables/useMask.js"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["input"])

let dateInput = ref("")
const mask = useMask("date")

setValue()

function setValue() {
  if (checkMoment(props.item.value)) {
    dateInput.value = formatMoment(props.item.value, "DD/MM/YYYY")
  }
}

function handleInput(value) {
  emit("input", value)
}
</script>