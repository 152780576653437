import coreApiClient from "@/providers/core-api/CoreApi"

export default {
  getPictureModels() {
    return coreApiClient.sendRequest("get", "/v1/checkin-picture-models", {})
  },
  uploadPicture(checkinId, payload) {
    return coreApiClient.sendRequest("post", `/v1/checkins/${checkinId}/pictures`, payload)
  },
  deletePicture(checkinPictureId){
    return coreApiClient.sendRequest("delete", `/v1/checkin-pictures/${checkinPictureId}`, {})
  }
}
