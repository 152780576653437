import coreApiClient from "@/providers/core-api/CoreApi"

export default {
  updateCheckin(checkinId, payload) {
    return coreApiClient.sendRequest("patch", `/v1/checkins/${checkinId}`, payload)
  },
  getCheckin(checkinId) {
    return coreApiClient.sendRequest("get", `/v1/checkins/${checkinId}`, {})
  }
}
