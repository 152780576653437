<template>
  <div>
    <div
      ref="cameraElement"
      class="fixed top-0 left-0 bottom-0 right-0 z-10 bg-plainWhite flex items-center wrap">
      <canvas
        ref="canvasElement"
        class="hidden"/>
      <video
        v-show="!pictureUrl"
        ref="webcamElement"
        :width="webcamWidth"
        :height="webcamHeight"
        autoplay="autoplay"
        playsinline="playsinline" />
      <div class="h-full flex justify-center items-center">
        <img
          :src="pictureUrl"
          alt="pictureUrl"
          v-if="pictureUrl">
      </div>
      <div
        v-if="isCameraLoading"
        class="absolute w-full flex flex-col justify-center items-center gap-4">
        <loading class="animate-spin w-8"/>
        Chargement de votre appareil photo...
      </div>
      <div
        v-else-if="isError || hasError"
        class="absolute left-4 right-4 bottom-24 bg-white rounded-lg px-4 py-2 shadow-md">
        <span>Une erreur est survenue, veuillez reprendre une nouvelle photo.</span>
      </div>

      <div
        class="absolute top-5 right-5"
        v-else>
        <Button
          @clicked="$emit('close')"
          label="Fermer"
          icon="close"
          type="danger"/>
      </div>

      <div class="absolute bottom-5 px-5 w-full flex justify-between">
        <template v-if="pictureUrl">
          <Button
            :is-disabled="isPictureLoading"
            class="only:mx-auto"
            label="Reprendre"
            @clicked="handleCancel" />

          <Button
            :is-loading="isPictureLoading"
            class="only:mx-auto"
            label="Utiliser"
            type="secondary"
            @clicked="handlePicture" />
        </template>

        <Button
          v-else
          @clicked="handleSnapshot"
          label="Capturer"
          icon="camera"
          type="primary"
          class="only:mx-auto"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineEmits, defineProps, onMounted, ref
} from "vue"
import Button from "@/components/ui/Button.vue"
import WebCam from "webcam-easy"

let isCameraLoading = ref(false)
let isPictureLoading = ref(false)
let isError = ref(false)
let isPictureTaken = ref(false)

let pictureUrl = ref(null)
let webcamWidth = ref(0)
let webcamHeight = ref(0)
let webcam = ref(null)

let blob = ref(null)

const webcamElement = ref(null)
const canvasElement = ref(null)
const cameraElement = ref(null)

const emit = defineEmits(["close", "picture"])

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  position: {
    type: String,
    required: true
  },
  picture: {
    type: Object,
    default: null
  },
  hasError: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  initWebcam()
  if (props.picture) {
    pictureUrl.value = props.picture.url
  }
})

function initWebcam() {
  isCameraLoading.value = true

  if (navigator.mediaDevices) {

    navigator.mediaDevices.getUserMedia({ video: true })
      .then((device) => {
        let webcamSettings = ref(device.getVideoTracks()[0].getSettings())
        let webcamRatio = ref(webcamSettings.value.width / webcamSettings.value.height)

        webcam.value = new WebCam(webcamElement, "environment", canvasElement)

        webcamWidth.value = cameraElement.value.clientWidth
        webcamHeight.value = webcamWidth.value / webcamRatio.value

        startWebcam()
      })

    return
  }

  isCameraLoading.value = false
  isError.value = false
}

function startWebcam() {
  if (webcam.value) {
    webcam.value.start()
      .catch(() => {
        isError.value = true
      })
      .finally(() => {
        isCameraLoading.value = false
      })
    return
  }
}

function handleSnapshot() {
  let snapshot = ref(webcam.value.snap())

  pictureUrl.value = snapshot.value
  blob.value = b64toBlob(snapshot.value)
  isPictureTaken.value = true
}

function handleCancel() {
  pictureUrl.value = null
  isPictureTaken.value = false
  startWebcam()
}

function handlePicture() {
  if (props.picture && !isPictureTaken.value) {
    emit("close")
    return
  }
  
  isPictureLoading.value = true

  let payload = ref(new FormData())
  payload.value.append("picture", blob.value, "picture.png")
  payload.value.append("position", props.position)
  payload.value.append("type", props.type)

  emit("picture", payload.value)
}

function b64toBlob(dataURI) {
  let byteString = ref(atob(dataURI.split(",")[1]))
  let ab = ref(new ArrayBuffer(byteString.value.length))
  var ia = ref(new Uint8Array(ab.value))

  for (var i = 0; i < byteString.value.length; i++) {
    ia.value[i] = byteString.value.charCodeAt(i)
  }
  return new Blob([ab.value], { type: "image/png" })
}
</script>