<template>
  <div class="bg-paperWhite first:rounded-t-lg last:rounded-b-lg relative">
    <div
      class="h-16 px-4 w-full flex justify-between items-center"
      @click="handleClick">
      <div class="flex flex-col truncate gap-1">
        <span class="flex-1 text-sm font-medium truncate">{{label}}</span>
        <span
          v-if="$slots.default"
          class="flex-1 text-xs text-gray03">
          <slot />
        </span>
      </div>
      <div class="flex flex-row items-center text-gray03 ml-3">
        <span
          v-if="$slots.additional"
          class="text-sm">
          <slot name="additional" />
        </span>
        <chevron-right class="w-[24px]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit("clicked")
    } 
  }
}
</script>