<template>  
  <FormPage
    :item="props.item"
    :current-value="selectedServices">
    <div class="flex flex-col gap-4">
      <div
        class="flex flex-col gap-2"
        v-if="pinnedServices.length">
        <span class="text-sm font-medium">Recommandées pour vous :</span>
        <ServiceItem
          v-for="service in pinnedServices"
          @update="handleUpdate"
          :key="service.id"
          :selected-services="selectedServices"
          :currency-code="props.item.currency_code"
          :service="service" />
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-sm font-medium">Toutes les prestations :</span>
        <ServiceCategory
          v-for="category in props.item.services"
          @update="handleUpdate"
          :key="category.id"
          :selected-services="selectedServices"
          :currency-code="props.item.currency_code"
          :category="category" />
      </div>
    </div>
  </FormPage>
</template>

<script setup>
import { computed, defineProps, ref } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import ServiceCategory from "@/components/ui/form/services/ServiceCategory"
import ServiceItem from "@/components/ui/form/services/ServiceItem"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const pinnedServices = computed(() => {
  let options = ref(JSON.parse(JSON.stringify(props.item.services)))
  let pinnedServices = []

  options.value.forEach(category => {
    category.services.map(service => {
      if (service.pinned && !pinnedServices.some(s => s.id == service.id)) {
        pinnedServices.push(service)
      }
    })
  })

  return pinnedServices
})

let selectedServices = ref(null)

setData()

function setData() {
  selectedServices.value = props.item.value
}

function handleUpdate(event) {
  selectedServices.value = event
}
</script>