<template>
  <div
    ref="picturePicker"
    @click.prevent="openCamera"
    class="font-bold bg-gray02 rounded-md
    flex items-center justify-center cursor-pointer
    overflow-hidden"
    :class="boxSizeClass">
    <plus
      v-if="!picture || !picture.pictureUrl"
      class="text-plainWhite"
      :class="iconSizeClass" />
    <img
      class="w-full h-full object-contain"
      v-else
      :src="picture.pictureUrl"
      alt="image">
  </div>

  <Camera
    v-if="isCameraOpen"
    @close="handleCameraClose"
    @picture="handlePicture"
    @delete="handleDelete"
    :picture="picture"
    :can-delete="canDelete"
    :type="type"
    :position="position">
    <img
      class="h-full absolute"
      v-if="hasModelUrl"
      :src="picture.modelUrl"
      alt="modèle">
  </Camera>
</template>

<script>
import Camera from "@/components/ui/camera/Camera.vue"

export default {
  name: "PicturePicker",
  components: { Camera },
  emits: ["close", "picture", "defect", "delete"],
  props: {
    isSmall: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    pictureId: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: null
    },
    autoOpen: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "main"
    },
    picture: {
      type: Object,
      default: null
    }
  },
  watch: {
    position: {
      immediate: true,
      handler() {
        if (this.autoOpen && (this.picture && !this.picture.pictureUrl)) {
          this.openCamera()
        }
      }
    },
  },
  data() {
    return { isCameraOpen: false }
  },
  computed: {
    boxSizeClass() {
      return this.isSmall ? "w-20 h-20" : "w-40 h-40"
    },
    iconSizeClass() {
      return this.isSmall ? "h-9" : "h-14"
    },
    hasModelUrl() {
      return this.picture && this.picture.modelUrl
    }
  },
  methods: {
    openCamera() {
      this.isCameraOpen = true
    },
    handleCameraClose() {
      this.isCameraOpen = false
    },
    handlePicture(newPicture) {
      this.$emit("picture", newPicture)
    },
    handleDelete(pictureId) {
      this.$emit("delete", pictureId)
    }
  },
}
</script>
