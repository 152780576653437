import { createStore } from "vuex"
import homeCheckin from "./homecheckin.module"
import workspaceCheckin from "./workspacecheckin.module"

export default createStore({
  modules: {
    homeCheckin,
    workspaceCheckin
  },
  strict: process.env.NODE_ENV !== "production"
})
