<template>
  <div
    class="font-medium rounded-lg select-none text-plainWhite active:shadow-md
    flex items-center gap-3
    transition-colors duration-200 ease-out"
    :class="buttonClass"
    @click.stop="handleClick">
    <component
      class="w-5"
      :class="{'animate-spin' : isLoading}"
      :is="buttonIcon"
      v-if="buttonIcon" />
    <span v-if="label">{{label}}</span>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "primary",
      validator: (value) => ["primary", "secondary", "dark", "danger"].indexOf(value) !== -1
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default",
      validator: (value) => ["small", "default"].indexOf(value) !== -1
    }
  },
  data() {
    const BACKGROUND_COLOR_MAP = {
      primary: "bg-deepSeaBlue hover:brightness-110",
      secondary: "bg-mandarineOrange hover:brightness-110",
      danger: "bg-bloodRed hover:brightness-110",
      dark: "bg-gray01 hover:brightness-110",
      disabled: "bg-gray03"
    }
    const BUTTON_SIZE_MAP = {
      small: "px-4 py-2 text-sm",
      default: "px-6 py-2 text-base"
    }

    return {
      BACKGROUND_COLOR_MAP,
      BUTTON_SIZE_MAP
    }
  },
  computed: {
    buttonIcon() {
      if (this.isLoading) {
        return "loading"
      }
      return this.icon
    },
    backgroundColor() {
      let type = this.isDisabled || this.isLoading ? "disabled" : this.type

      return this.BACKGROUND_COLOR_MAP[type]
    },
    buttonSize() {
      return this.BUTTON_SIZE_MAP[this.size]
    },
    buttonClass() {
      let cursorClass = this.isDisabled ? "cursor-not-allowed" : "cursor-pointer"
      return [this.backgroundColor, this.buttonSize, cursorClass].join(" ")
    }
  },
  methods: {
    handleClick() {
      if (this.isDisabled || this.isLoading) {
        return
      }

      this.$emit("clicked")
    }
  }
}
</script>
