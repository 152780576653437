<template>
  <FormPage
    :item="props.item"
    :current-value="inputValue">
    <Input
      size="full"
      has-small-border
      placeholder=""
      :mask="mask"
      @input="handleInput"
      v-model="inputValue" />
  </FormPage>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue"
import FormPage from "@/components/ui/form/FormPage"
import Input from "@/components/ui/Input.vue"
import useMask from "@/composables/useMask.js"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["input"])

let inputValue = ref("")
const mask = useMask("business_name")

setValue()

function setValue() {
  inputValue.value = props.item.value
}

function handleInput(value) {
  emit("input", value)
}
</script>