<template>
  <Page>
    <div class="text-center flex flex-col gap-2">
      <p>
        Vous venez de terminer l’état des lieux. Merci. Les informations ont été transmises à l’équipe <strong>{{ currentWorkspace.name }}</strong>.
      </p>
      <p>
        Un email contenant une confirmation d’état des lieux vient d’être envoyé à l’adresse <strong>{{ customer.email }}</strong>.
      </p>
    </div>


    <template v-slot:footer>
      <div class="flex justify-center">
        <Button
          @click="handleRestart"
          size="default"
          type="secondary"
          label="Nouvel état des lieux"
          icon="arrow-u-right-bottom" />
      </div>
    </template>
  </Page>
</template>

<script>
import Button from "@/components/ui/Button"
import Page from "@/components/workspace-checkin/navigation/Page.vue"

export default {
  name: "CheckinCompleted",
  components: {
    Page,
    Button 
  },
  computed: {
    currentWorkspace() {
      return this.$store.state.workspaceCheckin.workspace
    },
    customer() {
      return this.$store.state.workspaceCheckin.checkin.customer
    }
  },
  methods: {
    handleRestart() {
      location.reload()
    }
  }
}
</script>
