import coreApiClient from "@/providers/core-api/CoreApi"

export default {
  getAppointment(appointmentId) {
    return coreApiClient.sendRequest("get", `/v1/appointments/${appointmentId}`, {})
  },
  createAppointment(appointmentId, payload) {
    return coreApiClient.sendRequest("post", `/v1/appointments/${appointmentId}/checkins`, payload)
  },
  updateAppointment(appointmentId, payload) {
    return coreApiClient.sendRequest("patch", `/v1/appointments/${appointmentId}/checkins`, payload)
  },
  getLoanerContract(appointmentId) {
    return coreApiClient.sendRequest("get", `/v1/appointments/${appointmentId}/loan-contracts`, {})
  }
}
