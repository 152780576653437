<template>
  <div
    class="flex pl-4 pr-2 h-[50px] bg-paperWhite rounded-lg cursor-pointer text-sm gap-2"
    @click="isOpen = true">
    <div class="flex flex-col justify-center flex-1 truncate">
      <span class="truncate">
        {{ props.label }}
      </span>
      <span class="text-gray03">
        {{ props.subtitle }}
      </span>
    </div>
    <div class="flex items-center gap-1">
      <div
        class="flex items-center text-gray03"
        v-if="selectedOption">
        <span>{{ selectedOption }}</span>
      </div>
      <chevron-right class="w-5" />
    </div>

    <div
      class="fixed top-0 left-0 right-0 bottom-0 transition ease-in duration-200 flex justify-center"
      :class="[isOpen ? 'translate-x-0 sm:translate-y-0' : 'translate-x-full sm:translate-y-full sm:translate-x-0']">
      <div class="flex flex-col gap-4 max-w-[640px] w-full p-4 bg-plainWhite h-full">
        <div class="flex justify-between border-b border-paperWhite pb-4">
          <div class="flex flex-col">
            <span class="font-bold">
              {{ props.label }}
            </span>
            <span class="text-gray03">
              {{ props.subtitle }}
            </span>
          </div>
          <span
            class="flex gap-1"
            @click.stop="handleClose">
            <close class="w-5"/>
          </span>
        </div>
        <div class="flex flex-col gap-2 h-full">
          <div class="flex flex-col gap-2 flex-1">
            <slot />
          </div>
          <div class="flex justify-center">
            <Button
              label="Enregistrer"
              type="dark"
              @clicked="handleClose" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue"
import Button from "@/components/ui/Button"

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    default: null
  },
  selectedOption: {
    type: String,
    default: null
  }
})

let isOpen = ref(false)

function handleClose() {
  isOpen.value = false
}

// eslint-disable-next-line no-undef
defineExpose({ handleClose })
</script>