/* eslint-disable sort-imports */

import "maz-ui/css/main.css"
import "./styles/tailwind.css"
import "animate.css"

import App from "./App.vue"
import { createApp } from "vue"
import { vMaska } from "maska"
import router from "./router"
import store from "./store"
import icons from "@/plugins/icons.js"

// https://validatejs.org/
import validate from "validate.js"

// https://vue-i18n.intlify.dev/
import i18n from "./plugins/i18n.js"

// https://momentjs.com/docs/
import moment from "moment"
moment.locale("fr")

// https://louismazel.github.io/maz-ui-3/guide/getting-started
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput"

// https://www.npmjs.com/package/vue-drawing-canvas

const app = createApp(App)
app.component("MazPhoneNumberInput", MazPhoneNumberInput)
app.use(store)
app.use(router)
app.use(icons)
app.use(i18n)

app.config.globalProperties.$moment = moment
app.config.globalProperties.$validate = validate
app.directive("maska", vMaska)

app.mount("#app")
